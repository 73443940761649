import actions from './actions';

describe('actions', () => {
  describe('toggleSceneEditor', () => {
    it('should call mutation toggleSceneEditor and hideAllOverlay with exclusiveKey', () => {
      const commit = jest.fn();

      actions.toggleSceneEditor({ commit });

      expect(commit.mock.calls[0][0]).toBe('toggleSceneEditor');
      expect(commit.mock.calls[1][0]).toBe('hideAllOverlay');
      expect(commit.mock.calls[1][1]).toEqual({
        exclusiveKey: 'showSceneEditor'
      });
    });
  });

  describe('togglePlaybackExport', () => {
    it('should call mutation togglePlaybackExport and hideAllOverlay with exclusiveKey', () => {
      const commit = jest.fn();

      actions.togglePlaybackExport({ commit });

      expect(commit.mock.calls[0][0]).toBe('togglePlaybackExport');
      expect(commit.mock.calls[1][0]).toBe('hideAllOverlay');
      expect(commit.mock.calls[1][1]).toEqual({
        exclusiveKey: 'showPlaybackExportModal'
      });
    });
  });

  describe('navigate', () => {
    it('should call commit to setURL, register callback and busyHandler if busyHandler and callback are assigned and isBusy is true', () => {
      const spy = jest.fn();
      const commit = jest.fn();
      const getters = {
        isBusy: true,
      };
      const dispatch = () => {};
      const state = {
        busyHandler: spy
      };

      actions.navigate({
        commit, state, getters, dispatch
      }, {
        url: 'url',
        callback: () => {}
      });

      expect(spy).toBeCalledTimes(1);
      expect(commit.mock.calls[0]).toEqual(['setURL', 'url']);
      expect(commit.mock.calls[1][0]).toEqual('registerConfirmNavigationCallback');
    });
    it('should call dispatch with comfirmNavigation', () => {
      const commit = jest.fn();
      const getters = {
        isBusy: false,
      };
      const dispatch = jest.fn();
      const state = {};

      actions.navigate({
        commit, state, getters, dispatch
      }, {
        url: 'url',
        callback: () => {}
      });

      expect(commit.mock.calls[0]).toEqual(['setURL', 'url']);
      expect(commit.mock.calls[1][0]).toEqual('registerConfirmNavigationCallback');
      expect(dispatch.mock.calls[0]).toEqual(['confirmNavigation']);
    });
  });
  describe('confirimNavigation', () => {
    it('should commit with directToURL once if confirmNavigationCallback is undefined', () => {
      const commit = jest.fn();
      const state = {
        confirmNavigationCallback: undefined
      };

      actions.confirmNavigation({ state, commit });

      expect(commit.mock.calls[0][0]).toBe('directToURL');
      expect(commit.mock.calls[1][0]).toBe('clearURL');
    });
    it('should commit with directToURL once if confirmNavigationCallback a function return promise', () => {
      const commit = jest.fn();
      const spy = jest.fn().mockResolvedValue({});
      const state = {
        confirmNavigationCallback: spy
      };

      actions.confirmNavigation({ state, commit });

      expect(spy).toBeCalledTimes(1);
    });
  });
  describe('rejectNavigation', () => {
    it('should call commit with clearURL ', () => {
      const commit = jest.fn();

      actions.rejectNavigation({ commit });

      expect(commit).toBeCalledWith('clearURL');
    });
  });
  describe('clearDirty', () => {
    it('should call commit with clearDirty mutation', () => {
      const commit = jest.fn();

      actions.clearDirty({ commit }, 'test');

      expect(commit).toBeCalledWith('clearDirty', 'test');
    });
  });
});
